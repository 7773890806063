import { useEffect, useState } from "react";
import {
  HomeIcon,
  UsersIcon,
  GiftIcon,
  BanknotesIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import BalanceChart from "../component/BalanceChart";
import TransactionChart from "../component/TransactionChart";
import SalesChart from "../component/SalesChart";
import auth from "../services/AuthHeader";
import axios from "axios";
import TransactionPurchaseChart from "../component/TransactionPurchaseChart";
import noImage from "./../assets/images/img09.jpg";

const Dashboard = () => {
  const authTokenType = auth.AuthHeaderWithType();

  const urlTransaction =
    "https://backend.hellohome.casa/api/bestari/transaction";
  const urlProduct = "https://backend.hellohome.casa/api/bestari/product";
  const urlUser = "https://backend.hellohome.casa/api/bestari/user";
  const urlPurchase = "https://backend.hellohome.casa/api/bestari/purchase-card";

  const [dataTransaction, setDataTransaction] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [dataPurchase, setDataPurchase] = useState([]);

  const [loading, setLoading] = useState(true);

  // State untuk menyimpan data yang telah difilter
  const [filteredDataTransaction, setFilteredDataTransaction] = useState([]);

  // Fungsi untuk meningkatkan nomor
  let nomor = 1;

  const fetchDataTransact = async () => {
    try {
      const response = await axios.get(urlTransaction, {
        headers: authTokenType,
      });

      setDataTransaction(response.data);
    } catch (error) {

    }
    // finally {
    //   setLoading(false); // Loading selesai, tidak peduli berhasil atau gagal.
    // }
  };

  const fetchDataPurchase = async () => {
    try {
      const response = await axios.get(urlPurchase, {
        headers: authTokenType,
      });

      setDataPurchase(response.data);
    } catch (error) {

    } finally {
      setLoading(false); // Loading selesai, tidak peduli berhasil atau gagal.
    }
  };

  const fetchDataProduct = async () => {
    try {
      const response = await axios.get(urlProduct, {
        headers: authTokenType,
      });
      setDataProduct(response.data);
    } catch (error) {

    }
  };

  const fetchDataUser = async () => {
    try {
      const response = await axios.get(urlUser, {
        headers: authTokenType,
      });
      setDataUser(response.data);
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchDataTransact();
    fetchDataProduct();
    fetchDataUser();
    fetchDataPurchase();
  }, []);

  const filteredDataPendingTransaction = dataTransaction.data?.filter(
    (item) => item.status === "PENDING"
  );

  const filteredDataSuccessTransaction = dataTransaction.data?.filter(
    (item) => item.status === "SUCCESS"
  );

  const filteredDataRejectedTransaction = dataTransaction.data?.filter(
    (item) => item.status === "REJECTED"
  );

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 ">
          <div className="card card-statistic-2">
            <div className="card-stats pt-4">
              {/*<div className="card-stats-title">
                 Order Statistics -
                <div className="dropdown d-inline">
                  <a
                    className="font-weight-600 dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    id="orders-month"
                  >
                    August
                  </a>
                  <ul className="dropdown-menu dropdown-menu-sm">
                    <li className="dropdown-title">Select Month</li>
                    <li>
                      <a href="#" className="dropdown-item">
                        January
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item">
                        February
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item">
                        March
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item">
                        April
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item">
                        May
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item">
                        June
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item">
                        July
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item active">
                        August
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item">
                        September
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item">
                        October
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item">
                        November
                      </a>
                    </li>
                    <li>
                      <a href="#" className="dropdown-item">
                        December
                      </a>
                    </li>
                  </ul>
                </div> 
              </div>*/}
              <div className="card-stats-items d-flex justify-content-around">
                <div className="card-stats-item ">
                  <div className="card-stats-item-count">
                    {/* {filteredDataSuccessTransaction?.length} */}
                    {dataTransaction.data?.length}
                  </div>
                  {/* <div className="card-stats-item-label">Success</div> */}
                  <div className="d-none d-sm-inline card-stats-item-label">
                    <span>Total Subscriptions</span>
                  </div>
                  <div className="d-inline d-sm-none" >
                    <span style={{ fontSize: '8px' }}>Total Subscriptions</span>
                  </div>
                </div>
                {/* <div className="card-stats-item"> */}
                {/* <div className="card-stats-item-count"> */}
                {/* {filteredDataPendingTransaction?.length} */}
                {/* </div> */}
                {/* <div className="card-stats-item-label">Pending</div> */}
                {/* </div> */}
                <div className="card-stats-item">
                  <div className="card-stats-item-count">
                    {/* {filteredDataRejectedTransaction?.length} */}
                    {dataPurchase.data?.length}
                  </div>
                  {/* <div className="card-stats-item-label">Rejected</div> */}
                  <div className="d-none d-sm-inline card-stats-item-label">
                    <span>Purchase Cards</span>
                  </div>
                  <div className="d-inline d-sm-none" >
                    <span style={{ fontSize: '8px' }}>Purchase Cards</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="card-icon shadow-primary bg-primary">
                <BanknotesIcon width="26" style={{ color: "white" }} />
              </div>
              <div className="card-wrap ">
                <div className="card-header">
                  <h4>Total Transactions</h4>
                </div>
                <div className="card-body">
                  {dataTransaction.data?.length && dataPurchase.data?.length
                    ? dataTransaction.data?.length + dataPurchase.data?.length
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card card-statistic-2">
            <div className="card-chart">
              {/* <canvas id="balance-chart" height="80"></canvas> */}
              <BalanceChart dataProduct={dataProduct} />
            </div>
            <div className="card-icon shadow-primary bg-primary">
              <GiftIcon width="26" style={{ color: "white" }} />
            </div>
            <div className="card-wrap">
              <div className="card-header">
                <h4>Products</h4>
              </div>
              <div className="card-body">{dataProduct.data?.length}</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card card-statistic-2">
            <div className="card-chart">
              {/* <canvas id="sales-chart" height="80"></canvas> */}
              <SalesChart dataUser={dataUser} />
            </div>
            <div className="card-icon shadow-primary bg-primary">
              <UsersIcon width="26" style={{ color: "white" }} />
            </div>
            <div className="card-wrap">
              <div className="card-header">
                <h4>Users</h4>
              </div>
              <div className="card-body">
                {/* {dataUser.data?.length}*/}
                {dataUser.data?.filter(item => item.roles !== 'ADMIN').length}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4>Subscriptions & Purchase Card</h4>
            </div>
            <div className="card-body">
              {/* <canvas id="myChart" height="158"></canvas> */}
              {loading ? (
                <p>Loading...</p>
              ) : dataTransaction ? (
                // Tampilkan data transaction success & rejected setelah berhasil dimuat
                // <TransactionChart dataTransaction={dataTransaction} />
                <TransactionPurchaseChart
                  dataTransaction={dataTransaction}
                  dataPurchase={dataPurchase}
                />
              ) : (
                // Menampilkan pesan jika gagal mengambil data
                <p>Failed to fetch data.</p>
              )}
            </div>
          </div>
        </div>
        {/* <div className="col-lg-4">
          <div className="card gradient-bottom">
            <div className="card-header">
              <h4>Top 5 Products</h4>
              <div className="card-header-action dropdown">
                <a
                  href="#"
                  data-toggle="dropdown"
                  className="btn btn-danger dropdown-toggle"
                >
                  Month
                </a>
                <ul className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <li className="dropdown-title">Select Period</li>
                  <li>
                    <a href="#" className="dropdown-item">
                      Today
                    </a>
                  </li>
                  <li>
                    <a href="#" className="dropdown-item">
                      Week
                    </a>
                  </li>
                  <li>
                    <a href="#" className="dropdown-item active">
                      Month
                    </a>
                  </li>
                  <li>
                    <a href="#" className="dropdown-item">
                      This Year
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body" id="top-5-scroll">
              <ul className="list-unstyled list-unstyled-border">
                <li className="media">
                  <img
                    className="mr-3 rounded"
                    width="55"
                    src="stisla/img/products/product-3-50.png"
                    alt="product"
                  />
                  <div className="media-body">
                    <div className="float-right">
                      <div className="font-weight-600 text-muted text-small">
                        86 Sales
                      </div>
                    </div>
                    <div className="media-title">oPhone S9 Limited</div>
                    <div className="mt-1">
                      <div className="budget-price">
                        <div
                          className="budget-price-square bg-primary"
                          data-width="64%"
                        ></div>
                        <div className="budget-price-label">$68,714</div>
                      </div>
                      <div className="budget-price">
                        <div
                          className="budget-price-square bg-danger"
                          data-width="43%"
                        ></div>
                        <div className="budget-price-label">$38,700</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="media">
                  <img
                    className="mr-3 rounded"
                    width="55"
                    src="stisla/img/products/product-4-50.png"
                    alt="product"
                  />
                  <div className="media-body">
                    <div className="float-right">
                      <div className="font-weight-600 text-muted text-small">
                        67 Sales
                      </div>
                    </div>
                    <div className="media-title">iBook Pro 2018</div>
                    <div className="mt-1">
                      <div className="budget-price">
                        <div
                          className="budget-price-square bg-primary"
                          data-width="84%"
                        ></div>
                        <div className="budget-price-label">$107,133</div>
                      </div>
                      <div className="budget-price">
                        <div
                          className="budget-price-square bg-danger"
                          data-width="60%"
                        ></div>
                        <div className="budget-price-label">$91,455</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="media">
                  <img
                    className="mr-3 rounded"
                    width="55"
                    src="stisla/img/products/product-1-50.png"
                    alt="product"
                  />
                  <div className="media-body">
                    <div className="float-right">
                      <div className="font-weight-600 text-muted text-small">
                        63 Sales
                      </div>
                    </div>
                    <div className="media-title">Headphone Blitz</div>
                    <div className="mt-1">
                      <div className="budget-price">
                        <div
                          className="budget-price-square bg-primary"
                          data-width="34%"
                        ></div>
                        <div className="budget-price-label">$3,717</div>
                      </div>
                      <div className="budget-price">
                        <div
                          className="budget-price-square bg-danger"
                          data-width="28%"
                        ></div>
                        <div className="budget-price-label">$2,835</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="media">
                  <img
                    className="mr-3 rounded"
                    width="55"
                    src="stisla/img/products/product-3-50.png"
                    alt="product"
                  />
                  <div className="media-body">
                    <div className="float-right">
                      <div className="font-weight-600 text-muted text-small">
                        28 Sales
                      </div>
                    </div>
                    <div className="media-title">oPhone X Lite</div>
                    <div className="mt-1">
                      <div className="budget-price">
                        <div
                          className="budget-price-square bg-primary"
                          data-width="45%"
                        ></div>
                        <div className="budget-price-label">$13,972</div>
                      </div>
                      <div className="budget-price">
                        <div
                          className="budget-price-square bg-danger"
                          data-width="30%"
                        ></div>
                        <div className="budget-price-label">$9,660</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="media">
                  <img
                    className="mr-3 rounded"
                    width="55"
                    src="stisla/img/products/product-5-50.png"
                    alt="product"
                  />
                  <div className="media-body">
                    <div className="float-right">
                      <div className="font-weight-600 text-muted text-small">
                        19 Sales
                      </div>
                    </div>
                    <div className="media-title">Old Camera</div>
                    <div className="mt-1">
                      <div className="budget-price">
                        <div
                          className="budget-price-square bg-primary"
                          data-width="35%"
                        ></div>
                        <div className="budget-price-label">$7,391</div>
                      </div>
                      <div className="budget-price">
                        <div
                          className="budget-price-square bg-danger"
                          data-width="28%"
                        ></div>
                        <div className="budget-price-label">$5,472</div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="card-footer pt-3 d-flex justify-content-center">
              <div className="budget-price justify-content-center">
                <div
                  className="budget-price-square bg-primary"
                  data-width="20"
                ></div>
                <div className="budget-price-label">Selling Price</div>
              </div>
              <div className="budget-price justify-content-center">
                <div
                  className="budget-price-square bg-danger"
                  data-width="20"
                ></div>
                <div className="budget-price-label">Budget Price</div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h4>Users</h4>
              <div className="card-header-action">
                <NavLink to={"/user"} className="btn btn-danger">
                  View More <i className="fas fa-chevron-right"></i>
                </NavLink>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive table-invoice">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th>No</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      {/* <th>Action</th> */}
                    </tr>
                    {dataUser.data?.slice(-5).map((userData) => (
                      userData.roles !== 'ADMIN' && (
                        <tr key={userData.id}>
                          <td className="font-weight-600">{nomor++}</td>
                          <td>
                            <img
                              className=" img-thumbnail"
                              // height={70}
                              width={80}
                              src={
                                userData.image ?
                                  "https://backend.hellohome.casa/storage/app/public/" +
                                  userData.image : noImage
                              }
                              alt=""
                            />
                          </td>
                          <td className="font-weight-600">{userData?.name}</td>
                          <td className="font-weight-600">{userData?.email}</td>
                          <td>
                            {userData.email_verified_at ? (
                              <span className="badge badge-pill badge-success">
                                active
                              </span>
                            ) : (
                              <span className="badge badge-pill badge-danger">
                                Not Active
                              </span>
                            )}
                          </td>
                        </tr>
                      )
                    ))}

                    {/* <tr>
                      <td>
                        <a href="#">INV-87239</a>
                      </td>
                      <td className="font-weight-600">Kusnadi</td>
                      <td>
                        <div className="badge badge-warning">Unpaid</div>
                      </td>
                      <td>July 19, 2018</td>
                      <td>
                        <a href="#" className="btn btn-primary">
                          Detail
                        </a>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-4">
          <div className="card card-hero">
            <div className="card-header">
              <div className="card-icon">
                <i className="far fa-question-circle"></i>
              </div>
              <h4>14</h4>
              <div className="card-description">Customers need help</div>
            </div>
            <div className="card-body p-0">
              <div className="tickets-list">
                <a href="#" className="ticket-item">
                  <div className="ticket-title">
                    <h4>My order hasn't arrived yet</h4>
                  </div>
                  <div className="ticket-info">
                    <div>Laila Tazkiah</div>
                    <div className="bullet"></div>
                    <div className="text-primary">1 min ago</div>
                  </div>
                </a>
                <a href="#" className="ticket-item">
                  <div className="ticket-title">
                    <h4>Please cancel my order</h4>
                  </div>
                  <div className="ticket-info">
                    <div>Rizal Fakhri</div>
                    <div className="bullet"></div>
                    <div>2 hours ago</div>
                  </div>
                </a>
                <a href="#" className="ticket-item">
                  <div className="ticket-title">
                    <h4>Do you see my mother?</h4>
                  </div>
                  <div className="ticket-info">
                    <div>Syahdan Ubaidillah</div>
                    <div className="bullet"></div>
                    <div>6 hours ago</div>
                  </div>
                </a>
                <a
                  href="features-tickets.html"
                  className="ticket-item ticket-more"
                >
                  View All <i className="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Dashboard;
