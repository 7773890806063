import { useDispatch } from "react-redux";
import { userLogout } from "../features/userSlice";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import imageAvatar from "./../assets/images/avatar-1.png";
import { useEffect, useState } from "react";
import authHeader from "../services/AuthHeader";
import { NavLink } from "react-router-dom";
import { formatDistanceToNow, parseISO } from 'date-fns';
import {
  BanknotesIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";


// // integrasi backend/server
// const socket = io.connect("http://localhost:3002");
// // socket.emit("send_message", { message, room, uid }); //mengirim pesan sesuai dengan id room nya

// // useEffect(() => {
// socket.on("receive_notif", (data) => {
//   
// });
// // }, [socket]);

const Navbar = () => {
  const auth = useSelector((state) => state.user);

  const authTokenType = authHeader.AuthHeaderWithType();
  const [datas, setDatas] = useState([]);


  const getNotification = async () => {
    try {
      const req = await fetch(
        // "https://home.casa/api/bestari/notification-db",
        "https://backend.hellohome.casa/api/bestari/notification-db",
        {
          headers: authTokenType,
        }
      );
      const res = await req.json();

      setDatas(res.data);
      // setFilter(res.data);
    } catch (error) {

    }
  };

  // const getNotificationClearReadAt = async () => {
  //     
  //     try {
  //         const response = await axios.get('http://127.0.0.1:8000/api/bestari/notification-db-read-at', {
  //             headers: authTokenType
  //         })
  //         
  //         // setDatas(res.data);
  //         // setFilter(res.data);
  //     } catch (error) {
  //         
  //     }
  // };

  // const handleClickNotif = async (e) => {
  //     e.preventDefault();
  //     
  // const response = await axios.get('http://127.0.0.1:8000/api/bestari/notification-db-read-at', {
  //     headers: authTokenType
  // });
  // await getNotificationClearReadAt();
  // };
  useEffect(() => {
    getNotification();
  }, []);

  // useEffect(() => {
  //     getNotification();
  // }, []);


  const dispatch = useDispatch();
  return (
    <>
      <div className="navbar-bg"></div>
      <nav className="navbar navbar-expand-lg main-navbar">
        <form className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <a
                data-toggle="sidebar"
                className="nav-link nav-link-lg"
                style={{ cursor: "pointer" }}
              >
                <i className="fas fa-bars text-white"></i>
              </a>
            </li>
            {/* <li>
                            <a
                                href="#"
                                data-toggle="search"
                                className="nav-link nav-link-lg d-sm-none"
                            >
                                <i className="fas fa-search"></i>
                            </a>
                        </li> */}
          </ul>
          {/* <div className="search-element">
            <input
              className="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
              data-width="250"
            />
            <button className="btn" type="submit">
              <i className="fas fa-search"></i>
            </button>
            <div className="search-backdrop"></div>
            <div className="search-result">
              <div className="search-header">Histories</div>
              <div className="search-item">
                <a href="#">How to hack NASA using CSS</a>
                <a href="#" className="search-close">
                  <i className="fas fa-times"></i>
                </a>
              </div>
              <div className="search-item">
                <a href="#">Kodinger.com</a>
                <a href="#" className="search-close">
                  <i className="fas fa-times"></i>
                </a>
              </div>
              <div className="search-item">
                <a href="#">#Stisla</a>
                <a href="#" className="search-close">
                  <i className="fas fa-times"></i>
                </a>
              </div>
              <div className="search-header">Result</div>
              <div className="search-item">
                <a href="#">
                  <img
                    className="mr-3 rounded"
                    width="30"
                    src="stisla/img/products/product-3-50.png"
                    alt="product"
                  />
                  oPhone S9 Limited Edition
                </a>
              </div>
              <div className="search-item">
                <a href="#">
                  <img
                    className="mr-3 rounded"
                    width="30"
                    src="stisla/img/products/product-2-50.png"
                    alt="product"
                  />
                  Drone X2 New Gen-7
                </a>
              </div>
              <div className="search-item">
                <a href="#">
                  <img
                    className="mr-3 rounded"
                    width="30"
                    src="stisla/img/products/product-1-50.png"
                    alt="product"
                  />
                  Headphone Blitz
                </a>
              </div>
              <div className="search-header">Projects</div>
              <div className="search-item">
                <a href="#">
                  <div className="search-icon bg-danger text-white mr-3">
                    <i className="fas fa-code"></i>
                  </div>
                  Stisla Admin Template
                </a>
              </div>
              <div className="search-item">
                <a href="#">
                  <div className="search-icon bg-primary text-white mr-3">
                    <i className="fas fa-laptop"></i>
                  </div>
                  Create a new Homepage Design
                </a>
              </div>
            </div>
          </div> */}
        </form>
        <ul className="navbar-nav navbar-right">
          <li className="dropdown dropdown-list-toggle">
            {/* <a
              href="#"
              data-toggle="dropdown"
              className="nav-link nav-link-lg message-toggle beep"
            >
              <i className="far fa-envelope"></i>
            </a> */}
            {/* <div className="dropdown-menu dropdown-list dropdown-menu-right">
              <div className="dropdown-header">
                Messages
                <div className="float-right">
                  <a href="#">Mark All As Read</a>
                </div>
              </div>
              <div className="dropdown-list-content dropdown-list-message">
                <a href="#" className="dropdown-item dropdown-item-unread">
                  <div className="dropdown-item-avatar">
                    <img
                      alt="image"
                      src="stisla/img/avatar/avatar-1.png"
                      className="rounded-circle"
                    />
                    <div className="is-online"></div>
                  </div>
                  <div className="dropdown-item-desc">
                    <b>Kusnaedi</b>
                    <p>Hello, Bro!</p>
                    <div className="time">10 Hours Ago</div>
                  </div>
                </a>
                <a href="#" className="dropdown-item dropdown-item-unread">
                  <div className="dropdown-item-avatar">
                    <img
                      alt="image"
                      src="stisla/img/avatar/avatar-2.png"
                      className="rounded-circle"
                    />
                  </div>
                  <div className="dropdown-item-desc">
                    <b>Dedik Sugiharto</b>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit
                    </p>
                    <div className="time">12 Hours Ago</div>
                  </div>
                </a>
                <a href="#" className="dropdown-item dropdown-item-unread">
                  <div className="dropdown-item-avatar">
                    <img
                      alt="image"
                      src="stisla/img/avatar/avatar-3.png"
                      className="rounded-circle"
                    />
                    <div className="is-online"></div>
                  </div>
                  <div className="dropdown-item-desc">
                    <b>Agung Ardiansyah</b>
                    <p>
                      Sunt in culpa qui officia deserunt mollit anim id est
                      laborum.
                    </p>
                    <div className="time">12 Hours Ago</div>
                  </div>
                </a>
                <a href="#" className="dropdown-item">
                  <div className="dropdown-item-avatar">
                    <img
                      alt="image"
                      src="stisla/img/avatar/avatar-4.png"
                      className="rounded-circle"
                    />
                  </div>
                  <div className="dropdown-item-desc">
                    <b>Ardian Rahardiansyah</b>
                    <p>
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      ess
                    </p>
                    <div className="time">16 Hours Ago</div>
                  </div>
                </a>
                <a href="#" className="dropdown-item">
                  <div className="dropdown-item-avatar">
                    <img
                      alt="image"
                      src="stisla/img/avatar/avatar-5.png"
                      className="rounded-circle"
                    />
                  </div>
                  <div className="dropdown-item-desc">
                    <b>Alfa Zulkarnain</b>
                    <p>
                      Exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    </p>
                    <div className="time">Yesterday</div>
                  </div>
                </a>
              </div>
              <div className="dropdown-footer text-center">
                <a href="#">
                  View All <i className="fas fa-chevron-right"></i>
                </a>
              </div>
            </div> */}
          </li>
          <li className="dropdown dropdown-list-toggle" >
            <NavLink
              // onClick={handleClickNotif}
              data-toggle="dropdown"
              className="nav-link notification-toggle nav-link-lg beep"
            >
              <i className="far fa-bell"></i>
            </NavLink>
            <div className="dropdown-menu dropdown-list dropdown-menu-right">
              <div className="dropdown-header">
                Notifications
                {/* <div className="float-right">
                  <a href="#">Mark All As Read</a>
                </div> */}
              </div>
              <div className="dropdown-list-content dropdown-list-icons">

                {datas?.slice(0, 15).map((data) => (

                  data.datas === "New Purchase Card" ? (
                    <NavLink to={`/purchase/update/${data.notifiable_id}`} className="dropdown-item dropdown-item-unread" key={data.id}>
                      <div className="dropdown-item-icon bg-primary text-white">
                        <ShoppingCartIcon width="14" />
                      </div>
                      <div className="dropdown-item-desc">
                        {`${data?.datas} @${data?.from}`}
                        <div className="time text-primary">{formatDistanceToNow(parseISO(data.created_at), { addSuffix: true })}</div>
                      </div>
                    </NavLink>
                  ) : (
                    <NavLink to={`/transaction/details/${data.notifiable_id}`} className="dropdown-item dropdown-item-unread" key={data.id}>
                      <div className="dropdown-item-icon bg-success text-white">
                        <BanknotesIcon width="14" />
                      </div>
                      <div className="dropdown-item-desc">
                        {`${data?.datas} #HH-${data?.from}`}
                        {/* {`${data?.datas}`} */}
                        <div className="time text-primary">{formatDistanceToNow(parseISO(data.created_at), { addSuffix: true })}</div>
                      </div>
                    </NavLink>
                  )
                )
                )
                }

              </div>
              <div className="d-flex justify-content-around">
                <div className="dropdown-footer text-center">
                  <NavLink to={"/purchase"}>
                    View All Purchase<i className="fas fa-chevron-right"></i>
                  </NavLink>
                </div>
                <div className="dropdown-footer text-center">
                  <NavLink to={"/transaction"}>
                    View All Subscription <i className="fas fa-chevron-right"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </li>
          <li className="dropdown">
            <a
              href="#"
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"
            >
              <img
                alt="image"
                src={
                  auth.photoURL ?
                    "https://backend.hellohome.casa/storage/app/public/" +
                    auth.photoURL : imageAvatar
                }
                className="rounded-circle mr-1"
                style={{
                  width: "28px",
                  height: "28px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
              <div className="d-sm-none d-lg-inline-block">{`Hi, ${auth.displayName}`}</div>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              {/* <div className="dropdown-title">Logged in 5 min ago</div>
              <a
                href="features-profile.html"
                className="dropdown-item has-icon"
              >
                <i className="far fa-user"></i> Profile
              </a>
              <a
                href="features-activities.html"
                className="dropdown-item has-icon"
              >
                <i className="fas fa-bolt"></i> Activities
              </a>
              <a
                href="features-settings.html"
                className="dropdown-item has-icon"
              >
                <i className="fas fa-cog"></i> Settings
              </a> */}
              <div className="dropdown-divider"></div>
              <a
                onClick={() => dispatch(userLogout())}
                className="dropdown-item has-icon text-danger"
                style={{ cursor: "pointer" }}
              >
                <i className="fas fa-sign-out-alt"></i> Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
