const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="footer-left">
        Copyright &copy; 2024
        <div className="bullet"></div>
        {/* Design By <a href="#">Hello Home</a> */}
        <a href="#">HelloHome</a>
      </div>
      <div className="footer-right">1.0</div>
    </footer>
  );
};

export default Footer;
