import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
// import UserRegistration from "../hooks/UserRegistration";
import AuthService from "../services/auth.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { crudUser } from "./../utils/crudUser";
import axios from "axios";
import auth from "../services/AuthHeader";

const AddUser = () => {
  const navigate = useNavigate();
  const authTokenType = auth.AuthHeaderWithType();

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    photo: null,
  };
  const validationSchema = yup.object({
    name: yup.string().required().trim(),
    email: yup.string().required().email().trim(),
    password: yup.string().required().trim().min(6),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Password must match"),
    photo: yup.mixed().required(), //'mixed': jika berbentuk file, memakai 'mixed'
  });

  const onSubmit = async (values) => {
    const postData = {
      name: values.name,
      email: values.email,
      password: values.password,
      image: values.photo,
      roles: "ADMIN",
    };

    return await axios
      .post("https://backend.hellohome.casa/api/bestari/user", postData, {
        headers: authTokenType,
      })
      .then((response) => {
        // 
        if (response.data.message === "true") {
          // Notifification success
          toast.success("Add User Success!", {
            position: "top-center",
            autoClose: 2000, //2 detik
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/user");
            // window.location.reload();
          }, 2000);
        } else {
          toast.error("Add User Failed", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
    // register(values.name, values.email, values.password, values.photo);
    // 
  };
  return (
    <>
      <div className="section-header">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <h1>Users</h1>
      </div>
      <div className="card card-info">
        <div className="card-header">
          <h4>Add Admin </h4>
        </div>
        <div className="card-body ">
          <div className="row justify-content-center">
            <div className="col-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(props) => {
                  return (
                    <Form method="POST">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <Field
                          id="name"
                          type="text"
                          // className={
                          //   props.errors.name
                          //     ? "form-control is-invalid"
                          //     : "form-control"
                          // }
                          className="form-control"
                          name="name"
                        />

                        <ErrorMessage name="name">
                          {(error) => (
                            //   <div className="invalid-feedback">{error}</div>
                            <div className="text-danger">{error}</div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                          id="email"
                          type="email"
                          className="form-control"
                          name="email"
                        />
                        <ErrorMessage name="email">
                          {(error) => (
                            <div className="text-danger">{error}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Field
                          id="password"
                          type="password"
                          className="form-control"
                          name="password"
                        />
                        <ErrorMessage name="password">
                          {(error) => (
                            <div className="text-danger">{error}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="form-group">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <Field
                          id="confirmPassword"
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                        />
                        <ErrorMessage name="confirmPassword">
                          {(error) => (
                            <div className="text-danger">
                              confirm password is a required field
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="inputGroupFileAddon01"
                            >
                              Photo
                            </span>
                          </div>
                          <div className="custom-file">
                            <input
                              name="photo"
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              className="custom-file-input"
                              id="inputGroupFile01"
                              aria-describedby="inputGroupFileAddon01"
                              onChange={(e) =>
                                props.setFieldValue("photo", e.target.files[0])
                              }
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="inputGroupFile01"
                            >
                              Choose file
                            </label>
                          </div>
                        </div>
                        <ErrorMessage name="photo">
                          {(error) => (
                            <div className="text-danger">{error}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          disabled={props.isSubmitting || !props.isValid}
                          className={`btn btn-lg btn-block ${props.isSubmitting || !props.isValid
                            ? "btn-secondary"
                            : "btn-primary"
                            }`}
                        >
                          {props.isSubmitting ? "Please Wait" : "Add"}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
        <div className="card-footer "></div>
      </div>
    </>
  );
};

export default AddUser;
