import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
// import UserRegistration from "../hooks/UserRegistration";
import AuthService from "../services/auth.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import auth from "../services/AuthHeader";
import Select from "react-select";
import { format } from 'date-fns';
import { differenceInDays, parseISO } from 'date-fns';


const UpdateUser = () => {
    const authTokenType = auth.AuthHeaderWithType();
    const authToken = auth.AuthHeader();

    const { id } = useParams();
    const [defaultValueRoles, setDefaultValueRoles] = useState(null);
    const [loading, setLoading] = useState(true);
    const [countExpired, setCountExpired] = useState("");

    const [values, setValues] = useState({
        id: id,
        name: "",
        email: "",
        phone: "",
        password: "",
        address: "",
        city: "",
        zip_code: "",
        accumulated_expired: "",
        role: "",
        created_at: "",
    });

    const roles = [
        { value: "USER", label: "USER" },
        { value: "STAFF", label: "STAFF" },
        { value: "OWNER ", label: "OWNER" },
    ];

    useEffect(() => {
        axios
            .get("https://backend.hellohome.casa/api/bestari/user/" + id, {
                headers: authToken,
            })
            .then((res) => {

                setCountExpired(res.data.data.accumulated_expired);
                setValues({
                    ...values,
                    name: res.data.data.name,
                    email: res.data.data.email,
                    phone: res.data.data.phone,
                    address: res.data.data.address,
                    city: res.data.data.city,
                    zip_code: res.data.data.zip_code,
                    accumulated_expired: res.data.data.accumulated_expired,
                    roles: res.data.data.roles,
                    created_at: res.data.data.created_at,
                    // password: res.data.data.password,
                });
                setDefaultValueRoles({
                    value: res.data.data.roles,
                    label: res.data?.data?.roles
                    // .toLowerCase()
                    // .replace(/\b\w/g, (char) => char.toUpperCase()),
                });

                setLoading(false);

            })
        // .catch((err) => 
    }, []);

    const navigate = useNavigate();

    const initialValues = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        password: values.password,
        address: values.address,
        city: values.city,
        zip_code: values.zip_code,
        accumulated_expired: values.accumulated_expired,
        roles: values.roles,
        photo: values.photo,
        created_at: values.created_at,
    };
    const validationSchema = yup.object({
        name: yup.string().required().trim(),
        email: yup.string().required().email().trim(),
        phone: yup
            .number()
            .integer("the value must be an integer")
            .transform((value) => (isNaN(value) ? undefined : value))
            .positive("the value must be positive")
            // .required("phone is a required field"),
            .nullable(),
        address: yup.string().nullable().trim(),
        password: yup.string().nullable().trim().min(6),
        city: yup.string().nullable().trim(),
        zip_code: yup
            .number()
            .integer("the value must be an integer")
            .transform((value) => (isNaN(value) ? undefined : value))
            .positive("the value must be positive")
            // .required("zip code is a required field"),
            .nullable(),
        accumulated_expired: yup.string().nullable().trim(),
        roles: yup.string().nullable().trim(),
        photo: yup.mixed().nullable(), //'mixed': jika berbentuk file, memakai 'mixed'
    });

    const onSubmit = async (values) => {
        let postData = {
            name: values.name,
            email: values.email,
            phone: values.phone,
            address: values.address,
            city: values.city,
            zip_code: values.zip_code,
            accumulated_expired: values.accumulated_expired,
            roles: values.roles,
        };

        if (values.password) {
            postData = { ...postData, password: values.password };
        }

        if (values.photo) {
            postData = { ...postData, image: values.photo };
        }



        return await axios
            .post("https://backend.hellohome.casa/api/bestari/user/" + id, postData, {
                headers: authTokenType,
            })
            .then((response) => {


                if (response.data.message === "true") {
                    // Notifification success
                    toast.success("Add User Success!", {
                        position: "top-center",
                        autoClose: 2000, //2 detik
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        navigate("/user");
                        // window.location.reload();
                    }, 2000);
                } else {
                    toast.error("Add User Failed", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            });
    };
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className="section-header">
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <h1>Users</h1>
            </div>
            <div className="card card-info">
                <div className="card-header">
                    <h4>Edit User</h4>
                </div>
                <div className="card-body ">
                    <div className="row justify-content-center">
                        <div className="col-8">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                                enableReinitialize
                            >
                                {(props) => {
                                    return (
                                        <Form method="POST">
                                            {loading ? (
                                                <p>Loading</p>
                                            ) : (
                                                <>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span
                                                                    className="input-group-text"
                                                                    id="inputGroupFileAddon01"
                                                                >
                                                                    Photo
                                                                </span>
                                                            </div>
                                                            <div className="custom-file">
                                                                <input
                                                                    name="photo"
                                                                    type="file"
                                                                    accept="image/png, image/jpg, image/jpeg"
                                                                    className="custom-file-input"
                                                                    id="inputGroupFile01"
                                                                    aria-describedby="inputGroupFileAddon01"
                                                                    onChange={(e) =>
                                                                        props.setFieldValue("photo", e.target.files[0])
                                                                    }
                                                                />
                                                                <label
                                                                    className="custom-file-label"
                                                                    htmlFor="inputGroupFile01"
                                                                >
                                                                    Choose file
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="photo">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="name">Name</label>
                                                        <Field
                                                            id="name"
                                                            type="text"
                                                            // className={
                                                            //   props.errors.name
                                                            //     ? "form-control is-invalid"
                                                            //     : "form-control"
                                                            // }
                                                            className="form-control"
                                                            name="name"
                                                            value={values.name}
                                                            onChange={(e) =>
                                                                setValues({ ...values, name: e.target.value })
                                                            }
                                                        />

                                                        <ErrorMessage name="name">
                                                            {(error) => (
                                                                //   <div className="invalid-feedback">{error}</div>
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="email">Email</label>
                                                        <Field
                                                            id="email"
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            value={values.email}
                                                            onChange={(e) =>
                                                                setValues({ ...values, email: e.target.value })
                                                            }
                                                        />
                                                        <ErrorMessage name="email">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    {values.roles !== "ADMIN" && (
                                                        <div className="form-group">
                                                            <label htmlFor="phone">Phone</label>
                                                            <Field
                                                                id="phone"
                                                                type="number"
                                                                className="form-control"
                                                                name="phone"
                                                                value={values.phone}
                                                                onChange={(e) =>
                                                                    setValues({
                                                                        ...values,
                                                                        phone: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <ErrorMessage name="zip_code">
                                                                {(error) => (
                                                                    <div className="text-danger">{error}</div>
                                                                )}
                                                            </ErrorMessage>
                                                        </div>)}

                                                    <div className="form-group">
                                                        <label htmlFor="password">Password</label>
                                                        <Field
                                                            id="password"
                                                            type="password"
                                                            className="form-control"
                                                            name="password"
                                                            onChange={(e) =>
                                                                setValues({ ...values, password: e.target.value })
                                                            }
                                                        />
                                                        <ErrorMessage name="password">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    {values.roles !== "ADMIN" && (
                                                        <>
                                                            <div className="form-group">
                                                                <label htmlFor="city">City</label>
                                                                <Field
                                                                    id="city"
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="city"
                                                                    value={values.city}
                                                                    onChange={(e) =>
                                                                        setValues({ ...values, city: e.target.value })
                                                                    }
                                                                />
                                                                <ErrorMessage name="city">
                                                                    {(error) => (
                                                                        <div className="text-danger">{error}</div>
                                                                    )}
                                                                </ErrorMessage>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="zip_code">Zip Code</label>
                                                                <Field
                                                                    id="zip_code"
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="zip_code"
                                                                    value={values.zip_code}
                                                                    onChange={(e) =>
                                                                        setValues({
                                                                            ...values,
                                                                            zip_code: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                <ErrorMessage name="zip_code">
                                                                    {(error) => (
                                                                        <div className="text-danger">{error}</div>
                                                                    )}
                                                                </ErrorMessage>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="Textarea1">Address</label>
                                                                <Field
                                                                    as="textarea"
                                                                    className="form-control"
                                                                    id="Textarea1"
                                                                    name="address"
                                                                    rows="30"
                                                                    placeholder="Address"
                                                                    style={{ height: "100px" }}
                                                                    value={values.address}
                                                                    onChange={(e) =>
                                                                        setValues({
                                                                            ...values,
                                                                            address: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                <ErrorMessage name="address">
                                                                    {(error) => (
                                                                        <div className="text-danger">{error}</div>
                                                                    )}
                                                                </ErrorMessage>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="accumulated_expired	">Expired	</label>
                                                                <Field
                                                                    disabled
                                                                    id="accumulated_expired	"
                                                                    type="date"
                                                                    className="form-control"
                                                                    name="accumulated_expired	"
                                                                    value={values.accumulated_expired ?? ''}
                                                                    onChange={(e) => {
                                                                        setCountExpired(e.target.value);
                                                                        setValues({
                                                                            ...values,
                                                                            accumulated_expired: e.target.value,
                                                                        })
                                                                    }
                                                                    }
                                                                />
                                                                {/* {countExpired && <label className="text-danger">Expired Day Left : {differenceInDays(parseISO(countExpired), new Date())}</label>} */}
                                                                {countExpired && (
                                                                    <label className="text-danger">
                                                                        Expired Day Left : {differenceInDays(parseISO(countExpired), new Date()) < 0 ? 0 : differenceInDays(parseISO(countExpired), new Date())}
                                                                    </label>
                                                                )}
                                                                <ErrorMessage name="accumulated_expired	">
                                                                    {(error) => (
                                                                        <div className="text-danger">{error}</div>
                                                                    )}
                                                                </ErrorMessage>
                                                            </div>


                                                            <div className="form-group">
                                                                <label>Role</label>
                                                                <Select
                                                                    options={roles}
                                                                    name="roles"
                                                                    // onChange={(e) => 
                                                                    defaultValue={defaultValueRoles}
                                                                    onChange={(e) =>
                                                                        // 
                                                                        setValues({
                                                                            ...values,
                                                                            roles: e.value,
                                                                        })
                                                                    }
                                                                />
                                                                <ErrorMessage name="roles">
                                                                    {(error) => (
                                                                        <div className="text-danger">{error}</div>
                                                                    )}
                                                                </ErrorMessage>
                                                            </div>

                                                            <div className="form-group">

                                                                {values.created_at && (
                                                                    <label className="text-danger">
                                                                        <label className="badge badge-info text-white">Registered At : {format(new Date(values.created_at), 'd MMM yyyy')}</label>

                                                                    </label>
                                                                )}
                                                            </div>

                                                            {/* <div className="form-group">
                                                                <label className="badge badge-info text-white">Registered At : {format(new Date(values.created_at), 'MMM d yyyy')}</label>
                                                            </div> */}

                                                            {/* <div className="form-group">
                                                                <button
                                                                    type="submit"
                                                                    disabled={props.isSubmitting || !props.isValid}
                                                                    className={`btn btn-lg btn-block ${props.isSubmitting || !props.isValid
                                                                        ? "btn-secondary"
                                                                        : "btn-primary"
                                                                        }`}
                                                                >
                                                                    {props.isSubmitting ? "Please Wait" : "Update"}
                                                                </button>
                                                            </div> */}
                                                        </>
                                                    )
                                                    }
                                                    <div className="form-group">
                                                        <button
                                                            type="submit"
                                                            disabled={props.isSubmitting || !props.isValid}
                                                            className={`btn btn-lg btn-block ${props.isSubmitting || !props.isValid
                                                                ? "btn-secondary"
                                                                : "btn-primary"
                                                                }`}
                                                        >
                                                            {props.isSubmitting ? "Please Wait" : "Update"}
                                                        </button>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div className="card-footer "></div>
            </div >
        </>
    );
};

export default UpdateUser;
