import React, { useEffect, useState } from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import DataTable from "react-data-table-component";
import auth from "../services/AuthHeader";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import noImage from "./../assets/images/img09.jpg";

const Subscribe = () => {
  const authTokenType = auth.AuthHeaderWithType();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const columns = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      grow: 0,
      // sortable: true,
    },
    {
      name: "Photo",

      // selector: (row) => row.image,
      selector: (row) => (
        <img
          className="p-2 rounded-circle"
          // height={70}
          width={80}
          src={
            row.foto ?
              "https://backend.hellohome.casa/storage/app/public/" + row.foto :
              noImage
          }
          alt=""
        />
      ),
      // grow: 0,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Price Discount",
      selector: (row) => row.price_discount,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? (
          <span className="badge badge-pill badge-success">Active</span>
        ) : (
          <span className="badge badge-pill badge-danger">Not Active</span>
        ),
      sortable: true,
      // grow: 1,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div className="flex justify-conten-center">
          <NavLink
            className="btn btn-warning mx-1 my-1"
            to={`/subscribe/update/${row.id}`}
          >
            <PencilSquareIcon width="18" />
          </NavLink>

          <button
            className="btn btn-danger mx-1 my-1"
            onClick={() => handleClickDelete(row.id)}
          >
            <TrashIcon width="18" />
          </button>
        </div>
      ),
    },
  ];

  const getSubscribe = async () => {
    try {
      const req = await fetch(
        "https://backend.hellohome.casa/api/bestari/subscription",
        {
          headers: authTokenType,
        }
      );
      const res = await req.json();
      setData(res.data);
      setFilter(res.data);
    } catch (error) {

    }
  };

  useEffect(() => {
    getSubscribe();
  }, []);

  useEffect(() => {
    const result = data.filter((item) => {
      const lowerCaseSearch = search.toLocaleLowerCase();
      const matchesPriceDiscount = item.price_discount
        .toString()
        .match(lowerCaseSearch);
      const matchesCategory = item.category
        .toLowerCase()
        .match(lowerCaseSearch);
      const matchesPrice = item.price.toString().match(lowerCaseSearch);

      return matchesPriceDiscount || matchesCategory || matchesPrice;
    });
    setFilter(result);
  }, [search]);

  // delete modal
  const handleClose = () => {
    setShow(false);
  };

  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const handleDelete = () => {
    // 
    // const newdata = data.filter((item) => item.id !== deleteId);
    // setFilter(newdata);

    return axios
      .delete(
        `https://backend.hellohome.casa/api/bestari/subscription/${deleteId}`,
        {
          headers: authTokenType,
        }
      )
      .then((res) => {
        setShow(false);
        setSearch("");
        getSubscribe();

      }); //karena membutuhkan id, jadi seperti ini
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        color: "#ffffff",
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#408ACA",
      },
    },
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete Subscribe</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete it?</Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleDelete}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="section-header">
        <h1>Subscribes</h1>
      </div>

      {/* <div className="section-body"> */}
      <div className="card">
        <div className="card-header">
          <h4>All Subscribes</h4>
        </div>
        <DataTable
          className="px-4"
          customStyles={tableHeaderstyle}
          columns={columns}
          // data={data}
          data={filter}
          pagination
          // selectableRows
          // selectableRowsHighlight
          fixedHeader
          fixedHeaderScrollHeight="600px"
          highlightOnHover
          // actions={npm
          //   <NavLink end to={"/user/add"} className="btn btn-success mr-auto">
          //     Add User
          //   </NavLink>
          // }
          subHeader
          subHeaderComponent={
            <>
              <NavLink
                end
                to={"/subscribe/add"}
                className="btn btn-success mr-auto"
              >
                Add Subscribe
              </NavLink>
              <input
                type="text"
                className="form-control w-25 mb-2 mr-4"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        // subHeaderAlign="right"
        ></DataTable>
      </div>
    </>
  );
};

export default Subscribe;
