import React from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      bottom: -1,
      left: -1,
    },
  },
  datalabels: {
    display: false,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      border: {
        display: false,
      },
      grid: {
        display: false,
        // drawOnChartArea: true,
        drawTicks: true,
      },
      ticks: {
        beginAtZero: true,
        display: false,
      },
    },
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
        // drawOnChartArea: true,
        drawTicks: true,
      },
      ticks: {
        display: false,
      },
    },
  },
};

const SalesChart = (dataUser) => {
  // const dataUser = dataUser?.dataProduct?.data || [];
  const userData = dataUser?.dataUser?.data || [];
  // 

  // ----------------------------------------------------------------------------------
  // Misalkan pembelianData adalah properti dari data yang Anda terima
  // const pembelianData = this.props.pembelianData;

  // Mengelompokkan data pembelian berdasarkan tahun dan bulan
  const groupedData = userData?.reduce((result, pembelian) => {
    // Membuat objek Date dari created_at
    const tanggal = new Date(pembelian.created_at);
    const tahun = tanggal.getFullYear();
    const bulan = tanggal.getMonth() + 1; // getMonth() mulai dari 0, tambahkan 1

    // Membuat kunci untuk kelompok berdasarkan tahun dan bulan
    const key = `${tahun}-${bulan}`;

    // Menambahkan data pembelian ke grup yang sesuai
    if (!result[key]) {
      result[key] = { tahun, bulan, data: [] };
    }
    result[key].data.push(pembelian);

    return result;
  }, {});

  // 

  // Menghitung jumlah data pada setiap grup
  const jumlahData = Object.values(groupedData).map((group) => ({
    tahun: group.tahun,
    bulan: group.bulan,
    jumlah: group.data.length,
  }));
  // 

  const tahunBulan = [];
  const jumlahPerBulan = [];

  jumlahData.forEach((item) => {
    let dataTahunBulan = item.tahun + "-" + item.bulan;
    let dataJumlah = item.jumlah;
    // Menyimpan data dari iterasi
    tahunBulan.push(dataTahunBulan);
    jumlahPerBulan.push(dataJumlah);
  });
  // 
  // 

  const data = {
    type: "line",
    labels: tahunBulan,
    // [
    //   "16-07-2018",
    //   "17-07-2018",
    //   "18-07-2018",
    //   "19-07-2018",
    //   "20-07-2018",
    //   "21-07-2018",
    //   "22-07-2018",
    //   "23-07-2018",
    //   "24-07-2018",
    //   "25-07-2018",
    //   "26-07-2018",
    //   "27-07-2018",
    //   "28-07-2018",
    //   "29-07-2018",
    //   "30-07-2018",
    //   "31-07-2018",
    // ],

    datasets: [
      {
        label: "User",
        data: jumlahPerBulan,
        backgroundColor: "rgba(63,82,227,.2)",
        borderWidth: 3,
        borderColor: "rgba(63,82,227,1)",
        pointBorderWidth: 0,
        pointBorderColor: "transparent",
        pointRadius: 3,
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "rgba(63,82,227,1)",
        lineTension: 0.5,
      },
    ],
  };
  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default SalesChart;
