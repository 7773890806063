import axios from "axios";
import { useSelector } from "react-redux";

// const API_URL = "https://backend.hellohome.casa/api/auth";
const API_URL = "https://backend.hellohome.casa/api/auth";

const register = (name, email, password, photo) => {
  const postData = { name, email, password, photo };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data", // Atur Content-Type yang sesuai
    },
  };
  return axios
    .post(API_URL + "/register", postData, config)
    .then((response) => {


      // if (response.data.token) {
      //   localStorage.setItem("myuser", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const login = (email, password) => {

  return axios
    .post(API_URL + "/login-admin", {
      email,
      password,
    })
    .then((response) => {
      // if (response.data.token) {
      //   localStorage.setItem("myuser", JSON.stringify(response.data));
      // }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("myuser");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("myuser"));
};

const authService = {
  register,
  login,
  logout,
  getCurrentUser,
};

export default authService;
