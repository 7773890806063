import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
// import UserRegistration from "../hooks/UserRegistration";
import AuthService from "../services/auth.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { crudUser } from "./../utils/crudUser";
import axios from "axios";
import auth from "../services/AuthHeader";

const AddSubscribe = () => {
  const navigate = useNavigate();
  const authTokenType = auth.AuthHeaderWithType();

  const initialValues = {
    category: "",
    price: "",
    price_discount: "",
    photo: null,
  };
  const validationSchema = yup.object({
    category: yup.string().required().trim(),
    price: yup
      .number()
      .integer("the value must be an integer")
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("the value must be positive")
      .required("price is a required field"),
    price_discount: yup
      .number()
      .integer("the value must be an integer")
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("the value must be positive")
      .required("price discount is a required field"),
    photo: yup.mixed().required(), //'mixed': jika berbentuk file, memakai 'mixed'
  });

  const onSubmit = async (values) => {
    const postData = {
      category: values.category,
      price: values.price,
      price_discount: values.price_discount,
      foto: values.photo,
    };

    return await axios
      .post(
        "https://backend.hellohome.casa/api/bestari/subscription",
        postData,
        {
          headers: authTokenType,
        }
      )
      .then((response) => {
        // 
        if (response.data.message === "true") {
          // Notifification success
          toast.success("Add Subscribe Success!", {
            position: "top-center",
            autoClose: 2000, //2 detik
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/subscribe");
            // window.location.reload();
          }, 2000);
        } else {
          toast.error("Add Subscribe Failed", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
    // register(values.name, values.email, values.password, values.photo);
    // 
  };
  return (
    <>
      <div className="section-header">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <h1>Subscribes</h1>
      </div>
      <div className="card card-info">
        <div className="card-header">
          <h4>Add Subscribe</h4>
        </div>
        <div className="card-body ">
          <div className="row justify-content-center">
            <div className="col-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(props) => {
                  return (
                    <Form method="POST">
                      <div className="form-group">
                        <label htmlFor="category">Category</label>
                        <Field
                          id="category"
                          type="text"
                          // className={
                          //   props.errors.name
                          //     ? "form-control is-invalid"
                          //     : "form-control"
                          // }
                          className="form-control"
                          name="category"
                        />

                        <ErrorMessage name="category">
                          {(error) => (
                            //   <div className="invalid-feedback">{error}</div>
                            <div className="text-danger">{error}</div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label htmlFor="price">Price</label>
                        <Field
                          id="price"
                          type="number"
                          className="form-control"
                          name="price"
                        />
                        <ErrorMessage name="price">
                          {(error) => (
                            <div className="text-danger">{error}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="form-group">
                        <label htmlFor="price_discount">Price Discount</label>
                        <Field
                          id="price_discount"
                          type="number"
                          className="form-control"
                          name="price_discount"
                        />
                        <ErrorMessage name="price_discount">
                          {(error) => (
                            <div className="text-danger">{error}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="inputGroupFileAddon01"
                            >
                              Photo
                            </span>
                          </div>
                          <div className="custom-file">
                            <input
                              name="photo"
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              className="custom-file-input"
                              id="inputGroupFile01"
                              aria-describedby="inputGroupFileAddon01"
                              onChange={(e) =>
                                props.setFieldValue("photo", e.target.files[0])
                              }
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="inputGroupFile01"
                            >
                              Choose file
                            </label>
                          </div>
                        </div>
                        <ErrorMessage name="photo">
                          {(error) => (
                            <div className="text-danger">{error}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          disabled={props.isSubmitting || !props.isValid}
                          className={`btn btn-lg btn-block ${props.isSubmitting || !props.isValid
                            ? "btn-secondary"
                            : "btn-primary"
                            }`}
                        >
                          {props.isSubmitting ? "Please Wait" : "Add"}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
        <div className="card-footer "></div>
      </div>
    </>
  );
};

export default AddSubscribe;
