import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import parseISO from "date-fns/parseISO";
// import UserRegistration from "../hooks/UserRegistration";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// import { crudUser } from "./../utils/crudUser";
import axios from "axios";
import auth from "../services/AuthHeader";
import Select from "react-select";
import moment from "react-moment";

const UpdateProduct = () => {
  const navigate = useNavigate();
  const authTokenType = auth.AuthHeaderWithType();
  const authToken = auth.AuthHeader();

  const { id } = useParams();
  const [defaultValueName, setDefaultValueName] = useState(null);
  const [defaultValueVisibility, setDefaultValueVisibility] = useState(null);
  const [defaultValueType, setDefaultValueType] = useState(null);
  const [loading, setLoading] = useState(true);

  const [values, setValues] = useState({
    id: id,
    name: "",
    price: "",
    visibility: "",
    period: "",
    description: "",
  });

  useEffect(() => {
    axios
      .get("https://backend.hellohome.casa/api/bestari/product/" + id, {
        headers: authToken,
      })
      .then((res) => {

        setValues({
          ...values,
          name: res.data.data.name,
          price: res.data.data.price,
          visibility: res.data.data.visibility ? "On" : "Off",
          period: res.data.data.period,
          type: res.data.data.type,
          description: res.data.data.description,
        });

        setDefaultValueName({
          value: res.data.data.name,
          label: res.data.data.name,
        });

        const valueVisibility = res.data.data.visibility ? "On" : "Off";
        setDefaultValueVisibility({
          value: valueVisibility,
          label: valueVisibility,
        });

        setDefaultValueType({
          value: res.data.data.type,
          label:
            res.data.data.type === "subscription"
              ? "Subscription"
              : res.data.data.type === "purchase_card"
                ? "Purchase Card"
                : "Null",
        });

        setLoading(false);
      })
    // .catch((err) => 
  }, []);

  const name = [
    { value: "Basic", label: "Basic" },
    { value: "Premium", label: "Premium" },
  ];

  const visibility = [
    { value: "On", label: "On" },
    { value: "Off", label: "Off" },
  ];

  const type = [
    { value: "subscription", label: "Subscription" },
    { value: "purchase_card", label: "Purchase Card" },
  ];

  const initialValues = {
    name: values.name,
    price: values.price,
    visibility: values.visibility,
    period: values.period,
    type: values.type,
    description: values.description,
    image: values.image,
  };
  const validationSchema = yup.object({
    name: yup.string().trim(),
    price: yup
      .number()
      .integer("the value must be an integer")
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("the value must be positive")
      .required("price is a required field"),
    // visibility: yup.date(),
    period: yup
      .number()
      .integer("the value must be an integer")
      .positive("the value must be positive")
      .required("period is a required field"),
    visibility: yup.string().trim(),
    type: yup.string().trim(),
    description: yup.string().required().trim(),
    image: yup.mixed().nullable(),
  });

  const onSubmit = async (values) => {
    // Mendapatkan waktu sekarang
    const currentDateTime = new Date();

    // Mendapatkan tahun, bulan, dan tanggal
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    const day = String(currentDateTime.getDate()).padStart(2, "0");

    // Membuat string dengan format "yy-mm-dd"
    const formattedDate = `${year}-${month}-${day}`;

    // 
    // 
    // 
    let postData = {
      name: values.name,
      price: values.price,
      visibility: values.visibility === "On" ? formattedDate : null,
      period: values.period,
      type: values.type,
      description: values.description,
      // image: values.image,
    };

    if (values.image) {
      postData = { ...postData, image: values.image };
    }



    return await axios
      .post(
        "https://backend.hellohome.casa/api/bestari/product/" + id,
        postData,
        {
          headers: authTokenType,
        }
      )
      .then((response) => {

        if (response.data.message === "true") {
          // Notifification success

          toast.success("Upodate Product Success!", {
            position: "top-center",
            autoClose: 2000, //2 detik
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/product");
            // window.location.reload();
          }, 2000);
        } else {
          toast.error("Upodate Product Failed", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
  };
  return (
    <>
      <div className="section-header">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <h1>Products</h1>
      </div>
      <div className="card card-info">
        <div className="card-header">
          <h4>Edit Product</h4>
        </div>
        <div className="card-body ">
          <div className="row justify-content-center">
            <div className="col-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {(props) => {
                  return (
                    <Form method="POST">
                      {loading ? (
                        <p>Loading</p>
                      ) : (
                        <>
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <Field
                              id="name"
                              type="text"
                              className="form-control"
                              name="name"
                              value={values.name}
                              onChange={(e) =>
                                setValues({ ...values, name: e.target.value })
                              }
                            />
                            <ErrorMessage name="price">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>
                          {/* <div className="form-group">
                            <label>Name</label>
                            <Select
                              options={name}
                              name="name"
                              // onChange={(e) => 
                              // value={values.name}
                              defaultValue={defaultValueName}
                              onChange={(e) =>
                                // 
                                setValues({
                                  ...values,
                                  name: e.value,
                                })
                              }
                            />

                            <ErrorMessage name="name">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div> */}

                          <div className="form-group">
                            <label htmlFor="price">Price (€)</label>
                            <Field
                              id="price"
                              type="number"
                              className="form-control"
                              name="price"
                              value={values.price}
                              onChange={(e) =>
                                setValues({ ...values, price: e.target.value })
                              }
                            />
                            <ErrorMessage name="price">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label>Visibility</label>
                            <Select
                              options={visibility}
                              name="visibility"
                              // onChange={(e) => 
                              defaultValue={defaultValueVisibility}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  visibility: e.value,
                                })
                              }
                            />
                            <ErrorMessage name="visibility">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="period">Period</label>
                            <Field
                              id="period"
                              type="number"
                              className="form-control"
                              name="period"
                              value={values.period}
                              onChange={(e) =>
                                setValues({ ...values, period: e.target.value })
                              }
                            />
                            <ErrorMessage name="period">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label>Type</label>
                            <Select
                              options={type}
                              name="type"
                              // onChange={(e) => 
                              defaultValue={defaultValueType}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  type: e.value,
                                })
                              }
                            />
                            <ErrorMessage name="type">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="Textarea1">Description</label>
                            <Field
                              as="textarea"
                              className="form-control"
                              id="Textarea1"
                              name="description"
                              rows="30"
                              placeholder="Description"
                              style={{ height: "100px" }}
                              value={values.description}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  description: e.target.value,
                                })
                              }
                            />
                            <ErrorMessage name="description">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="inputGroupFileAddon01"
                                >
                                  Image
                                </span>
                              </div>
                              <div className="custom-file">
                                <input
                                  name="image"
                                  type="file"
                                  accept="image/png, image/jpg, image/jpeg"
                                  className="custom-file-input"
                                  id="inputGroupFile01"
                                  aria-describedby="inputGroupFileAddon01"
                                  onChange={(e) =>
                                    props.setFieldValue(
                                      "image",
                                      e.target.files[0]
                                    )
                                  }
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="inputGroupFile01"
                                >
                                  Choose file
                                </label>
                              </div>
                            </div>
                            <ErrorMessage name="image">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <button
                              type="submit"
                              disabled={props.isSubmitting || !props.isValid}
                              className={`btn btn-lg btn-block ${props.isSubmitting || !props.isValid
                                ? "btn-secondary"
                                : "btn-primary"
                                }`}
                            >
                              {props.isSubmitting ? "Please Wait" : "Update"}
                            </button>
                          </div>
                        </>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
        <div className="card-footer "></div>
      </div>
    </>
  );
};

export default UpdateProduct;
