import { getMessaging, getToken, onMessage } from "firebase/messaging";
import app from './firebaseConfig';

const messaging = getMessaging(app);

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BAHOL4YoDkj2thL5kXaLARHJI6KGgufdHmO4c_MZX4T1NPLzXGemrCQFjLCWE4UX47VWZwxUdPK1AdT0RU4ENGg" })
        .then((currentToken) => {
            if (currentToken) {
                // console.log('Token diperoleh:', currentToken);
                // Simpan token ke server Anda atau gunakan sesuai kebutuhan
            } else {
                // console.log('Tidak ada token yang tersedia. Mohon izinkan notifikasi.');
            }
        })
        .catch((err) => {
            // console.error('Gagal mendapatkan token:', err);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
