import React, { useState } from "react";
import { useParams } from "react-router-dom";
import logo from "./../HelloHome.png";
import {
  HomeIcon,
  UsersIcon,
  GiftIcon,
  BanknotesIcon,
  BellAlertIcon,
  ShoppingCartIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const menus = [
    {
      menuHeader: "Dashboard",
      name: "Dashboards",
      icon: <HomeIcon width="18" className="mx-1" />,
      to: "/dashbord",

      // submenu: [
      //   {
      //     name: "General Dashboard",
      //     to: "/",
      //   },
      //   {
      //     name: "Ecommerce Dashboard",
      //     to: "/",
      //   },
      // ],
    },
    {
      name: "Subscriptions",
      icon: <BanknotesIcon width="18" className="mx-1" />,
      to: "/transaction",
    },
    // {
    //   name: "Subscribes",
    //   icon: <BellAlertIcon width="18" className="mx-1" />,
    //   to: "/subscribe",
    // },
    {
      name: "Purchase",
      icon: <ShoppingCartIcon width="18" className="mx-1" />,
      to: "/purchase",
    },
    {
      name: "Products",
      icon: <GiftIcon width="18" className="mx-1" />,
      to: "/product",
    },
    {
      name: "Users",
      icon: <UsersIcon width="18" className="mx-1" />,
      to: "/user",
    },
    // {
    //   menuHeader: "Chat",
    //   name: "Chat",
    //   icon: <HomeIcon width="18" className="mx-1" />,
    //   to: "/chat",
    // },
  ];
  return (
    <>
      <div className="main-sidebar sidebar-style-2">
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <NavLink>
              <img src={logo} width={150} alt="" />
            </NavLink>
          </div>
          <div className="sidebar-brand sidebar-brand-sm">
            <a href="index.html">HH</a>
          </div>
          <ul className="sidebar-menu mt-5">
            {menus.map((menu, i) => (
              <React.Fragment key={i}>
                {menu.menuHeader && (
                  <li className="menu-header">{menu.menuHeader}</li>
                )}
                {/* <li className={menu.submenu && "nav-item dropdown active"}> */}
                <li
                  className={
                    splitLocation[1] === menu.to.split("/")[1] ? "active" : ""
                  }
                >
                  <NavLink
                    end
                    to={menu.to}
                    // className={`nav-link ${menu.submenu && "has-dropdown"}`}
                    className={`nav-link `}
                  >
                    {menu.icon}
                    <span className="ml-4">{menu.name}</span>
                  </NavLink>
                  {/* {menu.submenu && (
                    <ul className="dropdown-menu">
                      <li>
                        <NavLink className="nav-link">Test</NavLink>
                      </li>
                    </ul>
                  )} */}
                </li>
              </React.Fragment>
            ))}
          </ul>

          {/* <div className="mt-4 mb-4 p-3 hide-sidebar-mini">
            <a
              href="https://getstisla.com/docs"
              className="btn btn-primary btn-lg btn-block btn-icon-split"
            >
              <i className="fas fa-rocket"></i> Documentation
            </a>
          </div> */}
        </aside>
      </div>
    </>
  );
};

export default Sidebar;
