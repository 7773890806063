import { BrowserRouter } from "react-router-dom";
import Dashboard from "../src/pages/Dashboard";
import Footer from "./component/Footer";
import Navbar from "./component/Navbar";
import Sidebar from "./component/Sidebar";
import SetupRouter from "./routes";
import { useSelector } from "react-redux";

function App() {
  const auth = useSelector((state) => state.user);
  return (
    <BrowserRouter>
      <div id="app">
        {auth ? (
          <div className="main-wrapper">
            <Navbar />
            <Sidebar />
            <div className="main-content">
              <section className="section">
                <SetupRouter />
              </section>
            </div>
            <Footer />
          </div>
        ) : (
          <SetupRouter />
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
