import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCLnYt7-73k6Gm5-cxmsdS3HD9h8JCSNwI",
    authDomain: "hellohome-f58d5.firebaseapp.com",
    projectId: "hellohome-f58d5",
    storageBucket: "hellohome-f58d5.appspot.com",
    messagingSenderId: "832603898327",
    appId: "1:832603898327:web:34956b867a768054768b92",
};

const app = initializeApp(firebaseConfig);

export default app;
