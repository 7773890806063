import React, { useState, useEffect } from 'react';

const Countdown = ({ initialSeconds, onTimeout }) => {
    const [seconds, setSeconds] = useState(initialSeconds);
    // 
    useEffect(() => {
        let countdown;

        if (seconds > 0) {
            countdown = setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
        } else {
            onTimeout();
        }

        return () => clearTimeout(countdown);
    }, [seconds, onTimeout]);

    const formatTime = (time) => {
        const remainingSeconds = time % 60;
        return `${remainingSeconds}`;
    };

    return <h6 className="text-center">{formatTime(seconds)} Avant de rescanner</h6>;
};

export default Countdown;