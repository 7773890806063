import React, { useEffect, useState } from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import DataTable from "react-data-table-component";
import auth from "../services/AuthHeader";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import imagePurchase from "./../assets/images/img-purchase.jpg";
import { format } from 'date-fns';

const Purchase = () => {
  const authTokenType = auth.AuthHeaderWithType();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const columns = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      // grow: 0,
      width: "66px",
      // sortable: true,
    },
    {
      name: "Image",
      center: true,

      // selector: (row) => row.image,
      selector: (row) => (
        <img
          className="p-2 img-thumbnail"
          // height={70}
          width={80}
          src={imagePurchase}
          alt=""
        />
      ),
    },

    // {
    //   name: "Nick Name",
    //   selector: (row) => row.nick_name,
    //   sortable: true,
    // },

    // {
    //   name: "User Name",
    //   selector: (row) => row?.user?.name,
    //   sortable: true,
    // },

    {
      name: "User",
      selector: (row) => row?.user?.name,
      // sortable: true,
      cell: (row) => <NavLink to={`/user`}> {row?.user?.name}</NavLink>,
      sortable: true,
      width: "120px",
      sortFunction: (a, b) => {
        // Gantilah dengan fungsi perbandingan sesuai kebutuhan Anda
        if (a === b) return 0;
        return a ? 1 : -1;
      },
    },

    {
      name: "Product",
      selector: (row) => row?.product?.name,
      // sortable: true,
      cell: (row) => <NavLink to={`/product`}> {row?.product?.name}</NavLink>,
      sortable: true,
      width: "120px",
      sortFunction: (a, b) => {
        // Gantilah dengan fungsi perbandingan sesuai kebutuhan Anda
        if (a === b) return 0;
        return a ? 1 : -1;
      },
    },

    {
      name: "Recipient",
      selector: (row) => row.recipient,
      sortable: true,
    },
    {
      name: "Date Purchase",
      selector: (row) => format(new Date(row.start_date), 'MMM d yyyy'),
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => <span className={`badge ${row.status === 'pending' ? 'badge-warning' : row.status === 'delivered' ? 'badge-info' : row.status === 'completed' ? 'badge-success' : row.status === 'canceled' ? 'badge-danger' : row.status === 'block' ? 'badge-dark' : 'badge-primary'} text-uppercase`}>{row.status}</span>,
      sortable: true,
    },

    // {
    //   name: "City",
    //   selector: (row) => row.city,
    //   sortable: true,
    // },
    // {
    //   name: "Zip Code",
    //   selector: (row) => row.zip_code,
    //   sortable: true,
    // },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div className="flex justify-conten-center">
          <NavLink
            className="btn btn-warning mx-1 my-1"
            to={`/purchase/update/${row.id}`}
          >
            <PencilSquareIcon width="18" />
          </NavLink>

          <button
            className="btn btn-danger mx-1 my-1"
            onClick={() => handleClickDelete(row.id)}
          >
            <TrashIcon width="18" />
          </button>
        </div>
      ),
    },
  ];

  const getPurchaseCard = async () => {
    try {
      const req = await fetch(
        "https://backend.hellohome.casa/api/bestari/purchase-card",
        {
          headers: authTokenType,
        }
      );
      const res = await req.json();
      setData(res.data);
      setFilter(res.data);

    } catch (error) {

    }
  };

  useEffect(() => {
    getPurchaseCard();
  }, []);

  useEffect(() => {
    const result = data.filter((item) => {
      const lowerCaseSearch = search.toLocaleLowerCase();
      const matchesName = item?.user?.name?.toLowerCase().match(lowerCaseSearch);
      const matchesRecipient = item.recipient
        ?.toLowerCase()
        .match(lowerCaseSearch);
      // const matchesEmail = item.email.toLowerCase().match(lowerCaseSearch);
      const matchesPhone = item.phone?.toString().match(lowerCaseSearch);
      const matchesAddress = item.address?.toLowerCase().match(lowerCaseSearch);
      // const matchesCity = item.city?.toLowerCase().match(lowerCaseSearch);

      return (
        // matchesNickName ||
        matchesName ||
        matchesRecipient ||
        // matchesEmail ||
        matchesPhone ||
        matchesAddress
        // || matchesCity
      );
      // return item.name.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
  }, [search]);

  // delete modal
  const handleClose = () => {
    setShow(false);
  };

  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const handleDelete = () => {
    // 
    // const newdata = data.filter((item) => item.id !== deleteId);
    // setFilter(newdata);

    return axios
      .delete(
        `https://backend.hellohome.casa/api/bestari/purchase-card/${deleteId}`,
        {
          headers: authTokenType,
        }
      )
      .then((res) => {
        setShow(false);
        setSearch("");
        getPurchaseCard();

      }); //karena membutuhkan id, jadi seperti ini
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        color: "#ffffff",
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#408ACA",
      },
    },
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete it?</Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleDelete}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="section-header">
        <h1>Purchases</h1>
      </div>

      {/* <div className="section-body"> */}
      <div className="card">
        <div className="card-header">
          <h4>All Purchases</h4>
        </div>
        <DataTable
          className="px-4"
          customStyles={tableHeaderstyle}
          columns={columns}
          // data={data}
          data={filter}
          pagination
          // selectableRows
          // selectableRowsHighlight
          fixedHeader
          fixedHeaderScrollHeight="600px"
          highlightOnHover
          // actions={npm
          //   <NavLink end to={"/user/add"} className="btn btn-success mr-auto">
          //     Add User
          //   </NavLink>
          // }
          subHeader
          subHeaderComponent={
            <>
              <NavLink
                end
                to={"/purchase/add"}
                className="btn btn-success mr-auto"
              >
                Add Purchase
              </NavLink>
              <input
                type="text"
                className="form-control w-25 mb-2 mr-4"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        // subHeaderAlign="right"
        ></DataTable>
      </div>
    </>
  );
};

export default Purchase;
