import React, { useEffect, useState } from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import DataTable from "react-data-table-component";
import auth from "../services/AuthHeader";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
// import QrCode from "react-qr-code";
import QRCode from "qrcode.react";
import noImage from "./../assets/images/img09.jpg";
import {
  ArrowDownTrayIcon,
  LinkIcon
} from "@heroicons/react/24/solid";

const User = () => {
  const authTokenType = auth.AuthHeaderWithType();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const downloadQR = (data) => {
    const canvas = document.getElementById(data);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = data + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const columns = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      grow: 0,
      // sortable: true,
    },
    {
      name: "Image",

      // selector: (row) => row.image,
      selector: (row) => (
        <img
          className="p-2 img-thumbnail"
          // height={70}
          width={80}
          src={
            // di hosting didin
            // https://backend.hellohome.casa/storage/app/public/
            // di hosting greeg
            // https://backend.hellohome.casa/storage/
            row.image ?
              // "https://backend.hellohome.casa/storage/" + row.image :
              "https://backend.hellohome.casa/storage/app/public/" + row.image :
              noImage
            // di local
            // row.image
            //   ? "https://backend.hellohome.casa/storage/" + row.image
            //   : noImage
          }
          alt=""
        />
      ),
      // grow: 0,
    },
    {
      name: "Name",
      selector: (row) => row.roles === 'ADMIN' ? <span className="badge badge-pill badge-success" >{row.name}</span> : row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,

      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.roles !== 'ADMIN' ?
          row.email_verified_at ? (
            <span className="badge badge-pill badge-success">active</span>
          ) : (
            <span className="badge badge-pill badge-danger">Not Active</span>
          )
          : <span className="badge badge-pill badge-warning" >Admin</span>,
      sortable: true,
      // grow: 1,
    },
    {
      name: "Qr Code & URL",
      selector: (row) => (
        <>
          <div className="d-none">
            <QRCode
              id={`qr-${row.name}`}
              value={row.roles === 'OWNER' ? `https://frontend.hellohome.casa/company/view/${row?.purchase_card?.card_code_hash}` : ''}
              size={500}
              level={"H"}
              includeMargin={true}
            />
          </div>
          <div className="">
            {row.roles === 'OWNER' &&
              <>
                <a href="#" className="text-white mr-3" onClick={() => downloadQR(`qr-${row.name}`)}>
                  <ArrowDownTrayIcon width="26" className="" color="#00A5DA" />
                  {/* <p>{row.ownership_id ?? row.id}</p> */}
                </a>
                <a href="#" className="text-white ml-3" onClick={(e) => {
                  e.preventDefault();
                  const downloadUrl = `https://frontend.hellohome.casa/company/view/${row?.purchase_card?.card_code_hash ?? ''}`;
                  navigator.clipboard.writeText(downloadUrl)
                    .then(() => alert('Copy URL has been copied!'))
                  // .catch((err) => 
                }}>
                  <LinkIcon width="26" className="" color="#00A5DA" />
                </a>
              </>
            }

          </div>
        </>
      ),
      sortable: false,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div className="flex justify-conten-center">
          <NavLink
            className="btn btn-warning mx-1 my-1"
            to={`/user/update/${row.id}`}
          >
            <PencilSquareIcon width="18" />
          </NavLink>

          <button
            className="btn btn-danger mx-1 my-1"
            onClick={() => handleClickDelete(row.id)}
          >
            <TrashIcon width="18" />
          </button>
        </div>
      ),
    },
  ];

  const getUser = async () => {
    try {
      const req = await fetch(
        "https://backend.hellohome.casa/api/bestari/user",
        {
          headers: authTokenType,
        }
      );
      const res = await req.json();
      setData(res.data);

      // Filter data berdasarkan peran (role) "User"
      // const filteredData = res.data.filter(item => item.roles !== "ADMIN");


      // setFilter(filteredData);
      setFilter(res.data);
    } catch (error) {

    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    const result = data.filter((item) => {
      return item.name.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
  }, [search]);

  // delete modal
  const handleClose = () => {
    setShow(false);
  };

  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const handleDelete = () => {
    // 
    // const newdata = data.filter((item) => item.id !== deleteId);
    // setFilter(newdata);

    return axios
      .delete(`https://backend.hellohome.casa/api/bestari/user/${deleteId}`, {
        headers: authTokenType,
      })
      .then((res) => {
        setShow(false);
        setSearch("");
        // window.location.reload();
        getUser();

      }); //karena membutuhkan id, jadi seperti ini
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        color: "#ffffff",
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#408ACA",
      },
    },
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete it?</Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleDelete}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="section-header">
        <h1>Users</h1>
      </div>

      {/* <div className="section-body"> */}
      <div className="card">
        <div className="card-header">
          <h4>All Users</h4>
        </div>
        <DataTable
          className="px-4"
          customStyles={tableHeaderstyle}
          columns={columns}
          // data={data}
          data={filter}
          pagination
          // selectableRows
          // selectableRowsHighlight
          fixedHeader
          fixedHeaderScrollHeight="600px"
          highlightOnHover
          // actions={npm
          //   <NavLink end to={"/user/add"} className="btn btn-success mr-auto">
          //     Add User
          //   </NavLink>
          // }
          subHeader
          subHeaderComponent={
            <>
              <NavLink end to={"/user/add"} className="btn btn-success mr-auto">
                Add Admin
              </NavLink>
              <input
                type="text"
                className="form-control w-25 mb-2 mr-4"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        // subHeaderAlign="right"
        ></DataTable>
      </div>
    </>
  );
};

export default User;
